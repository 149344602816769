#PageNotFound {
    font-size: 100px;
    position: absolute;
    top: calc(50vh - 50px);
    left: calc(50vw - 93.6px);
}

#logoCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 125px;
    z-index: 1000;
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;
}

#logoPic {
    padding-left: 5px;
    height: 50px;
    aspect-ratio: 1/1.12;
}