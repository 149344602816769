html {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Added Font Families Below*/
@font-face {
  font-family: 'GT Walsheim Pro Bold';
  src: url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Bold.eot') format('embedded-opentype');
  src: url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Bold.eot') format('embedded-opentype'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Bold.woff2') format('woff2'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Bold.woff') format('woff'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim Pro Regular';
  src: url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Regular.eot') format('embedded-opentype');
  src: url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Regular.eot') format('embedded-opentype'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Regular.woff2') format('woff2'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Regular.woff') format('woff'),
       url('../public/fonts/Gtwalsheimprotrial/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}