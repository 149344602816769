#Card {
    width: 375px;
    max-width: 90vw;
    height: 500px;
    border-radius: 30px;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;

    margin: 25px;
}

#overlay {
    height: calc(100% - 25px);
    width: calc(100% - 25px);
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));

    opacity: 1; /* Initially hide the overlay */
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;

    border-radius: 25px;
    padding-bottom: 25px;
    padding-left: 25px;

    transition-duration: 0.5s;
}

/* Show overlay on hover */
#overlay:hover{
    background-color: rgba(0, 0, 0, 0.35);
    padding-left: 25px;
}

#orgPos {
    font-size: 17px;
}

#orgName {
    color: white;
    font-family: "GT Walsheim Pro Bold", sans-serif;
    font-size: 26px;
    line-height: 120%;
}

/* Styles for touch-enabled devices */
@media (hover: none) and (pointer: coarse) {
    #overlay {
        opacity: 1;
        padding-bottom: 15px;
        padding-left: 15px;
        width: calc(100% - 15px);
        height: calc(100% - 15px);

        background-color: rgba(0, 0, 0, 0.65);
    }
}