#App {
  background-color: black;
  color: white;
  font-family: "GT Walsheim Pro Bold", sans-serif;
  width: 100%;
  margin: 0;
  min-height: 100vh;
}

.title {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 50px;
}

.text {
  color: #999;
  line-height: 24px;
  font-size: 18px;
  font-family: "GT Walsheim Pro Regular", sans-serif;
}

.button {
  color: rgb(255, 255, 255, 0.86);
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 3px;
  background-color: transparent;
  padding: 12px 15px 12px 0px;
  border: 0;
  border-bottom: 1px solid rgb(95, 153, 255);
  transition-duration: 0.4s;
}

.button:hover {
  background-color: rgba(95, 153, 255, 0.15);
  padding: 12px 10px 12px 10px;
}

.section {
  margin: 0;
  width: calc(100% - 10%);
  padding: 6vh 5%;
}

@media (max-width: 1500px) {
  .section {
    width: 95%;
    padding: 5vh 2.5%;
  }
}

.sectionFlexSimple  {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  max-width: 100vw;
}

.titleDesc {
  color: rgb(95, 153, 255);
  font-size: 14px;
  letter-spacing: 2px;
  padding-bottom: 15px;
}

/* Copied from W3 Schools Responsive iFrame Page */
.iframeCont {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin: 40px 0;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}
