body {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}


.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998; /* Set the z-index to a value that ensures the navbar appears above other elements */
  height: 100px;
  background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.8) 59%, transparent);
}

#logoPic {
  height: 38px;
  padding-left: 5px;
}

.menu-icon {
  display: none;
}

#hamPic {
  width: 40px;
  height: 40px;
}

.nav-elements ul li:hover{
  cursor: pointer;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-right: 5px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #5f99ff;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: rgba(0, 0, 0, 0.87);
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}