#DigitalScholars {
    padding-top: 7vh;
    justify-content: center;
    margin: 0;
}

#DSTitleCont {
    margin-right: 5vw;
    margin-top: 8vh;
}

.squareBox {
    margin-left: 25px;
    margin-right: 25px;
}

#DSDesc {
    max-width: 60ch;
}

@media (max-width: 1434px) {
    #DSTitleCont {
        margin-right: 0;
    }
}

@media (max-width: 1399px) {
    #DSTitleCont {
        text-align: center;
    }
    #DSVidDesc {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
    }

    #DigitalScholars {
        padding-top: 4vh;
    }
}