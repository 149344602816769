#Intro {
    min-height: 700px;
    padding-top: 5vh;
    /*width: calc(100% - 20%);
    padding: 5vh 10%;*/
}

@media (max-width: 1142px) {
    #Intro {
        padding-top: 125px;
    }
}

@media (min-height: 875px) {
    #Intro {
        padding-top: 150px;
    }
}

#nameCont {
    max-width: 100vw;
}

.hello {
    font-size: 28px;
    font-weight: 700;
}

#dot {
    color: #5f99ff;
    height: 20px;
    width: 20px;
    background-color: #5f99ff;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

#picCont {
    max-width: 100vw;
}

#headshot {
    height: calc(100vw - 50px);
    max-height: 600px;
    aspect-ratio: 1/1;
    background-color: black;
    float: right;
    filter: grayscale(0%);
    padding: 25px;
}


/*Typewriter Effect Start*/
