#ProgTitleDesc {
    margin-top: 20px;
}

#ProgTitle {
    font-size: 60px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

@media (max-width: 630px) {
    #ProgTitle {
        font-size: 50px;
    }
}

#githubBtn {
    margin-bottom: 10px;
}

#skillsBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-evenly;
    margin-top: 5vh;
}

.halfBox {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}