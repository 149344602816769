#AboutMe {
    font-size: 50px;
    margin-bottom: 25px;
}

#aboutTitleCont {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: 100vw;
    padding: 20px 5px;
}

#aboutTitle {
    margin-bottom: 12px;
}

#aboutText {
    max-width: 70ch;
    padding: 20px 5px;
}

@media (max-width: 1383px) {
    #aboutTitleCont {
        align-items: center;
        text-align: center;
    }
}